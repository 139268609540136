<template>
    <div>
        <page-header></page-header>

        <!-- Heading and Filename -->
        <div>
            <table class="header-section">
                <td style="width:30%;">
                    IMPORT TASKS (DRY RUN)
                </td>
                <td style="color: grey;">
                    {{ fileName }}
                </td>
            </table>
        </div>

        <!-- Dry Run Information -->
        <div class="info-box">
            <span class="dryrun-span" title="dry run is a simulation where the database stays unaffected">Dry run</span> has
            been completed
            <li>Total records: {{ importedTasks.length }}</li>
            <li>Invalid records: {{ invalidTaskCount }}</li>
            <li>Valid tasks: {{ validTaskCount }}</li>
            <p></p>
            <li>Tasks added to the database: 0</li>
        </div>

        <div class="issuesDisplay">
            <div v-if="Object.keys(groupedIssues).length">
                <div v-for="taskNo in Object.keys(groupedIssues)" :key="taskNo" class="grey-line">
                    <!-- Display all issues for this task number -->
                    <ul>
                        <br><li style="font-weight:bold" v-for="(issue, index) in groupedIssues[taskNo]" :key="index">
                            Task: {{ taskNo }} (Row: {{ getRowNumber(issue) + 1 }})
                        </li>
                        <ul>
                            <li style="font-weight:bold" v-for="(issue, index) in groupedIssues[taskNo]" :key="index">
                                {{ issue.message }}
                            </li>
                        </ul>
                    </ul>
                    <i style="color:grey; margin-left:20px;">Provided fields</i>
                    <div class="issue-list">
                        <ol>
                            <li><strong>Task no: &nbsp;</strong> {{ getTaskByNo(parseInt(taskNo)).task_no }}</li>
                            <li><strong>Module: &nbsp;</strong> {{ getTaskByNo(parseInt(taskNo)).module }}</li>
                            <li><strong>Chapter No: &nbsp;</strong> {{ getTaskByNo(parseInt(taskNo)).chapt_no }}</li>
                            <li><strong>Location: &nbsp;</strong> {{ getTaskByNo(parseInt(taskNo)).location }}</li>
                            <li><strong>AMTOSS: &nbsp;</strong> {{ getTaskByNo(parseInt(taskNo)).AMTOSS }}</li>
                            <li><strong>Activity: &nbsp;</strong> {{ getTaskByNo(parseInt(taskNo)).activity }}</li>
                            <li><strong>Category: &nbsp;</strong> {{ getTaskByNo(parseInt(taskNo)).category }}</li>
                            <li><strong>Level: &nbsp;</strong> {{ getTaskByNo(parseInt(taskNo)).level }}</li>                      
                        </ol>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
  
  
<script>
import pageHeader from '@/components/Examiner/Header.vue';

export default {
    data() {
        return {
            productId: this.$route.params.productId,
            csvFileName: "",  // Initialized as an empty string
            entireFileRows: [],
            previewRows: [],
        };
    },
    components: {
        pageHeader,
    },
    computed: {
        issues() {
            return this.$store.state.issues;
        },
        importedTasks() {
            return this.$store.state.importedTasks;
        },
        invalidTaskCount() {
            const uniqueIssueTasks = new Set(this.issues.map(issue => issue.task_no));
            return uniqueIssueTasks.size;
        },
        validTaskCount() {
            const uniqueImportedTasks = new Set(this.importedTasks.map(task => task.task_no));
            return uniqueImportedTasks.size - this.invalidTaskCount;
        },
        groupedIssues() {
            return this.issues.reduce((acc, issue) => {
                (acc[issue.task_no] = acc[issue.task_no] || []).push(issue);
                return acc;
            }, {});
        },
        fileName() {
            return this.$store.state.fileName;
        }
    },
    methods: {
        displayIssues() {
            console.log("Import Issues:", this.issues);
        },
        getRowNumber(issue) {
            const taskNo = issue.task_no ? issue.task_no : '';
            // Find index in the importedTasks
            const index = this.importedTasks.findIndex(task => task.task_no === taskNo);
            return index; // +1 because CSV row numbers start from 1
        },
        getTaskByNo(taskNo) {
    if (!isNaN(taskNo)) {
        const task = this.importedTasks.find((task) => task.task_no !== undefined && task.task_no === taskNo);
        return task;
    } else {
        // If task_no is undefined, find the task by row number
        const rowIndex = this.getRowNumber(taskNo); // Use task_no parameter here
        //console.log(this.importedTasks[rowIndex]);
        return rowIndex !== -1 ? this.importedTasks[rowIndex] : {};
    }
},

    },
    mounted() {
        console.log("importedTasks:", this.importedTasks);
        console.log("issues from Vuex:", this.$store.state.issues);
    }
}
</script>

 
<style scoped>
    .grey-line {
        border-top: 1px solid rgb(179, 179, 179); /* Add a grey border on the top to create the line effect */
        margin-top: 10px; /* Adjust the margin as needed */
        margin-bottom: 10px; /* Adjust the margin as needed */
        max-width: 100%;
    }
.header-section {
    width: 99%;
    text-align: left;
    height: 90%;
    margin-left: 1%;
}

.header-section td {
    font-size: 28px;
}

.info-box {
    border: 1px solid #ecb1b1;
    padding: 20px;
    margin: 0px 20px;
    color: #ca3f4b;
    font-weight: bold;
    background-color: #F8D7DA;
    width: 60%;
    height: 180px;
    text-align: left;
}


.issuesDisplay {
    text-align: left;
    margin-top: 20px;
}


.issue-list {
    margin-left: 1%;
    background-color: rgb(250, 248, 248);
    width: 98%;
}

.dryrun-span {
    border: none;
}
</style>
  
<template>
  oject
  <div class="trainee-list-modal-overlay" @click="closeModalOutside">
    <div class="trainee-list-modal" @click.stop>
      <div class="modal-content">
        <h2>Edit Task ID: {{ newTask.task_id }}</h2>
        <div class="add">
          <form @submit.prevent="updateTask">
            <div class="form-group">
              <label for="task_no">Task No:</label>
              <input type="text" class="form-control rounded" id="task_no" name="task_no" placeholder="Enter Task Number"
                v-model="newTask.task_no" />
            </div>

            <div class="form-group">
              <label for="description">Description:</label>
              <input type="text" class="form-control rounded" id="description" name="description"
                placeholder="Enter Task Description" v-model="newTask.description" />
            </div>

            <div class="form-group">
              <label for="reference">Reference:</label>
              <input type="text" class="form-control rounded" id="reference" name="reference"
                placeholder="Enter Task Reference" v-model="newTask.reference" />
            </div>

            <div class="form-group">
              <label for="scope">Scope(s):</label>
              <select class="form-control rounded" id="scopePart1" name="scopePart1" v-model="scopePart1">
                <option value=""></option>
                <option value="LOC">LOC</option>
                <option value="SGH">SGH</option>
                <option value="TS">TS</option>
                <option value="FOT">FOT</option>
                <option value="MEL">MEL</option>
              </select>
              <select class="form-control rounded" id="scopePart2" name="scopePart2" v-model="scopePart2">
                <option value=""></option>
                <option value="LOC">LOC</option>
                <option value="SGH">SGH</option>
                <option value="TS">TS</option>
                <option value="FOT">FOT</option>
                <option value="MEL">MEL</option>
              </select>
            </div>

            <div class="form-group">
              <label for="category">Category:</label>
              <input type="text" class="form-control rounded" id="category" name="category"
                placeholder="Enter Category" v-model="newTask.category" />
            </div>

            <div class="form-group">
              <label for="chapt_no">Chapter #:</label>
              <input type="text" class="form-control rounded" id="chapt_no" name="chapt_no"
                placeholder="Enter Chapter #" v-model="newTask.chapt_no" />
            </div>

            <div class="form-group">
              <label for="est_time">Est. Time (minutes):</label>
              <input type="text" class="form-control rounded" id="est_time" name="est_time"
                placeholder="Enter Est. Time" v-model="newTask.est_time" />
            </div>

            <div class="form-group">
              <label for="is_exam">Is Examinable:</label>
              <select class="form-control rounded" id="is_exam" name="is_exam" v-model="newTask.is_exam">
                <option value=""></option>
                <option value="1">True</option>
                <option value="0">False</option>
              </select>
            </div>


            <div class="form-group">
              <label for="manob">Manob:</label>
              <select class="form-control rounded" id="manob" name="manob" v-model="newTask.manob">
                <option value=""></option>
                <option value="M">M</option>
                <option value="O">O</option>
              </select>
            </div>

            <!-- <div class="form-group">
              <label for="level">Level:</label>
              <select class="form-control rounded" id="level" name="level" v-model="newTask.level">
                <option value=""></option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div> -->

            <div v-if="showAlert" class="alert alert-danger error-message" role="alert">
              <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
            </div>
            <div>
              <button class="btn btn-primary rounded" type="submit">Update Task</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import axios from 'axios';

export default {
  data() {
    return {
      instructors: [],
      products: [],
      tasks: [],
      newTask: {
        task_no: '',
        reference: '',
        manob: '',
        scope: '',
        est_time: '',
        is_exam: '',
        description: '',
        chapt_no: '',
      },
      showAlert: false,
      currentTime: '',
      errorMessages: {},
      errorMessage: '',
      productSelected: false,
      taskSelected: false,
      showTraineeList: false,
      scopePart1: '',
      scopePart2: '',
    };
  },
  methods: {

    async updateTask() {
      try {
        const task_id = localStorage.getItem('task_id');
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        console.log('task_id')
        console.log(this.newTask);
            // Check if task_no is a valid integer
    if (isNaN(parseInt(this.newTask.task_no))) {
      this.errorMessage = 'Task No must be a valid integer.';
      this.showAlert = true;
      return;
    }

    // Check if chapt_no is a valid integer
    if (isNaN(parseInt(this.newTask.chapt_no))) {
      this.errorMessage = 'Chapter # must be a valid integer.';
      this.showAlert = true;
      return;
    }

    // Check if est_time is not blank and is a valid integer
    if (this.newTask.est_time !== null && isNaN(parseInt(this.newTask.est_time))) {
      this.errorMessage = 'Est. Time must be a valid integer.';
      this.showAlert = true;
      return;
    }

        // Check if both scope parts are non-empty before concatenating
        console.log(this.scopePart2);
const scope = `${this.scopePart1}${this.scopePart2 !== undefined && this.scopePart2 !== '' ? `|${this.scopePart2}` : ''}`;
console.log(scope);


        const response = await axios.put(
          `/api/tasks/${task_id}`,
          {
            task_no: this.newTask.task_no,
            reference: this.newTask.reference,
            manob: this.newTask.manob,
            scope: scope,
            est_time: this.newTask.est_time,
            is_exam: this.newTask.is_exam,
            description: this.newTask.description,
            chapt_no: this.newTask.chapt_no,
          },
          { headers }
        );

        if (response.status === 200) {
          window.alert('Task updated Successfully');
          this.tasks.push(response.data);
          this.newTask = {
            task_no: '',
        reference: '',
        manob: '',
        scope: '',
        est_time: '',
        is_exam: '',
        description: '',
        chapt_no: '',
          };
          this.errorMessage = '';
          this.showAlert = false;
        }
        await this.fetchTasks();
        window.location.reload();
      } catch (error) {
        console.error('Error adding User:', error.response);


        if (error.response && error.response.data && error.response.data.errors) {
          // Handle validation errors
          const validationErrors = error.response.data.errors;

          // Build an error message from the validation errors
          let errorMessage = 'Validation errors:';
          for (const field in validationErrors) {
            errorMessage += `\n${field}: ${validationErrors[field][0]}`;
          }

          this.errorMessage = errorMessage;
        } else {
          // Handle other errors
          this.errorMessage = 'An error occurred while adding the user.';
        }

        this.showAlert = true;
      }
    },
    async fetchTasks() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('/api/tasks', { headers });
        console.log('task response')
        console.log(response);

        if (response.status === 200) {
          this.tasks = response.data;
          console.log('tasks');
          console.log(this.tasks);
        }
      } catch (error) {
        console.error('Error retrieving tasks:', error);
        // Handle error
      }
    },

    goBack() {
      this.$router.push({ name: 'ViewTasks' });
    },
    closeModalOutside() {
      this.$emit('close'); // Emit an event to indicate the modal should be closed
    },
    getTaskData(task_id) {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        axios.get(`/api/tasks/${task_id}`, { headers })
          .then(res => {
            this.newTask = res.data;
            console.log(res.data);
            const scopeParts = res.data.scope.split('|');
            this.scopePart1 = scopeParts[0];
            this.scopePart2 = scopeParts[1];
            console.log(this.scopePart1);
            console.log(this.scopePart2);
          });
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    },
  },
  async mounted() {
    this.getTaskData(localStorage.getItem('task_id'));
    await this.fetchTasks();

  },
}
</script>
  
<style scoped>
.trainee-list-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure the modal is on top of other content */
}

h2 {
  margin-top: 30px;
  font-size: 30px;
  margin-bottom: 30px;
}

/* Style your modal content */
.trainee-list-modal {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 800px;
  /* Limit the maximum width of the modal */
  padding: 20px;
  position: relative;
  max-height: 95%;
}


.modal-content {
  max-height: 90vh;
  /* Adjust the maximum height as needed */
  overflow-y: auto;
  /* Enable scrolling within the modal content */
}


.add button {
  width: 200px;
  height: 40px;
  margin-top: 50px;
  margin-bottom: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

.add{
  margin-top: 0;
  height:90%;
}
button.btn:hover {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

input.form-control,
select.form-control {
  width: 65%;
  margin-left: 150px;
  display: inline-block;
  margin-bottom: 25px;
  align-items: baseline;
}

.form-group {
  margin-bottom: 15px;
}

label {
  margin-right: 5px;
  position: absolute;
  margin-top: 8px;
}

.form-control {
  border-radius: 5px;
}

.form-control#start_date .form-control#end_date {
  border-radius: 5px;
  width: 25%;
  margin-left: 100px;
  margin-right: 45%;
}

h1 {
  margin-bottom: 20px;
}
</style>
  
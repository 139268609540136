<template>
    <div class="modal-overlay" @click="closeModal">
        <div class="products-modal" @click.stop>
            <div class="modal-content" @click.stop> <!-- stop the click event from propagating to the overlay -->
                <h2>Add a New Product</h2>
                <table>
                    <!-- <tr>
                        <select class="aircraftdropdown" v-model="selectedAircraft">
                            <option value="" disabled selected>Select Aircraft</option>
                            <option v-for="aircraft in aircrafts" :key="aircraft.aircraft_id" :value="aircraft.aircraft_id">
                                {{ aircraft.name }}
                            </option>
                        </select>

                    </tr>
                    <tr>
                        <select class="specificdropdown" v-model="selectedSpecific">
                            <option value="">Select Specific</option>
                            <option v-for="specific in specifics" :key="specific.specific_id" :value="specific.specific_id">
                                {{ specific.name }}
                            </option>
                        </select>
                    </tr> -->
                    <tr>
                        <div v-if="suggestedProjectName">
                            <p> Suggested Name: {{ suggestedProjectName }} </p>
                        </div>
                        <input class="productsearch" v-model="productName" type="text" style="width: 100%"
                            placeholder="Product Name" />
                    </tr>
                    <tr>
                        <div class="dropdown">
      <button @click="toggleDropdown" style="width:300px; background-color:rgb(166, 190, 166)">Select columns for Tasks</button>
      <div class="dropdown-menu" :class="{ 'dropdown-menu-active': showDropdown }">
        <div class="lists-container">
                    <div class="list">
                        <h3 style="font-size: 16px;"><b>Available Columns</b></h3>
                        <ul>
                            <li v-for="column in allColumns" :key="column" @click="assignColumn(column)">
                                {{ column }}
                            </li>
                        </ul>
                    </div>
                    <div class="assign-button">
                        <button @click="assignSelectedColumns">&rarr;</button>
                        <button @click="removeSelectedColumns">&larr;</button>
                    </div>
                    <div class="list">
                        <h3 style="font-size: 16px;"><b>Selected Columns</b></h3>
                        <ul>
                            <li v-for="column in selectedColumns" :key="column" @click="removeColumn(column)">
                                {{ column }}
                            </li>
                        </ul>
                    </div>
                </div>
                </div>
                </div>
                    </tr>
                    <tr>
                        <button style="width: 40%;" class="saveButton" @click="saveButton">Save</button>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            allColumns: ['Task No.', 'Module', 'Chapter No', 'Location', 'Area', 'AMTOSS', 'Activity', 'Category', 'Level', 'Manob', 'Ratings'],
      selectedColumns: ['Task No.', 'AMTOSS', 'Area', 'Activity', 'Manob'], // default selected columns
            aircrafts: [],
            specifics: [],
            selectedAircraft: 'A380',
            selectedSpecific: 'RR Trent-700',
            productName: '',
            showDropdown: false,
        };
    },
//     watch: {
//     selectedColumns() {
//       this.savePreferences(); // Save when selectedColumns changes
//     }
//   },
    // computed: {
    // suggestedProjectName() {
    //     if (this.selectedAircraft) {
    //         const aircraftName = this.aircrafts.find(a => a.aircraft_id === this.selectedAircraft).name;
            
    //         if (this.selectedSpecific) {
    //             const specificName = this.specifics.find(s => s.specific_id === this.selectedSpecific).name;
    //             return `${aircraftName} ${specificName} Practical Training`;
    //         } else {
    //             return `${aircraftName} Practical Training`;
    //         }
    //     }
    //     return '';
    // },
//},


    mounted() {
        this.fetchData();
        window.addEventListener('show-alert', this.handleShowAlert);

         // Remove selected columns from available columns
         this.allColumns = this.allColumns.filter(column => !this.selectedColumns.includes(column));
    },
    beforeUnmount() {
        window.removeEventListener('show-alert', this.handleShowAlert);
    },


    methods: {
        toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    selectAllColumns(checked) {
      if (checked) {
        this.selectedColumns = [...this.allColumns];
      } else {
        this.selectedColumns = [];
      }
      this.savePreferences();
    },
        async fetchData() {
            try {
                //eslint-disable-next-line
                debugger;
                const token = localStorage.getItem('token');
                const headers = { Authorization: `Bearer ${token}` };
                let response = await axios.get('/api/aircraft', { headers });
                this.aircrafts = response.data;
                console.log(response);

                response = await axios.get('/api/specifics', { headers });
                this.specifics = response.data;
                console.log(this.specifics);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
        closeModal() {
            location.reload();
            this.$emit('close');
        },
        assignColumn(column) {
            if (!this.selectedColumns.includes(column)) {
                this.selectedColumns.push(column);

                // Remove the selected column from the available columns
                const index = this.allColumns.indexOf(column);
                if (index !== -1) {
                    this.allColumns.splice(index, 1);
                }
            }
        },
        removeColumn(column) {
            const index = this.selectedColumns.indexOf(column);
            if (index !== -1) {
                this.selectedColumns.splice(index, 1);

                // Add the removed column back to the available columns
                this.allColumns.push(column);
            }
        },
        assignSelectedColumns() {
            // Implement this method to assign multiple selected columns at once
        },
        removeSelectedColumns() {
      // Implement the logic to remove multiple selected columns at once
      if (this.selectedColumns.length > 0) {
        // Move all selected columns back to available columns
        this.allColumns = [...this.allColumns, ...this.selectedColumns];
        // Clear the selected columns array
        this.selectedColumns = [];
      }
    },
        async saveButton() {
            //eslint-disable-next-line
            debugger;
            // Check if values are set before making the API call
            if (this.selectedAircraft && this.productName) {
                try {
                    const token = localStorage.getItem('token');
                    const headers = { Authorization: `Bearer ${token}` };
                    // Set spec_id to null if selectedSpecific is an empty string
                    //const spec_id = this.selectedSpecific === '' ? null : this.selectedSpecific;

                    const data = {
                        ac_id: 1,
                        spec_id: 1,
                        name: this.productName
                    };
                    console.log("data", data);
                    const response = await axios.post('/api/products', data, { headers });

                    if (response.status === 201) {
                        window.alert('Product added Successfully');
                        this.closeModal();
                    } else {
                        window.dispatchEvent(new CustomEvent('show-alert', { detail: "Product addition failed" }));
                    }
                } catch (error) {
                    console.error("There was an error with the fetch operation:", error);
                }
            } else {
                console.error("Please select an aircraft, and enter a product name.");
            }
        }

    }
};

</script>

<style scoped>
.lists-container {
    display: flex;
    justify-content: space-between;
}

.list {
    width: 200px;
    padding: 8px;
    margin-left: 20px;
}

.lists-container ul {
    list-style: none;
    padding: 0;
}

.lists-container ul li {
    cursor: pointer;
}

.assign-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; /* Updated to stack buttons vertically */
}

.assign-button button {
    font-size: 20px;
    background-color: #0a407a;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 5px; /* Optional: Add margin between buttons */
    margin-right: 20px;
}
.dropdown {

  display: inline-block;
  margin-bottom: 10px;
  margin-right: 200px;
}

.dropdown-menu {
  display: none;
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 1;
  position: absolute;
}
/* Display the dropdown menu when showDropdown is true */
.dropdown-menu-active {
    display: grid;
  grid-template-columns: repeat(3, 1fr); /* Display 3 columns */
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 1;
  position: absolute;
  width: 600px;
}

table {
  border-collapse: collapse;
}

th,
td {
  border: 1px solid black;
  padding: 8px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.products-modal {
    background-color: #fff;
    max-width: 300px;
    max-height: 90vh;
    max-width: 90%;
    width: 40%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    position: relative;
    display: block;
    z-index: 1001;
}

.aircraftdropdown,
.productsearch,
.specificdropdown {
    height: 40px;
    /* adjust this value as needed --*/
    margin-bottom: 10px;
    width: 80%;
}


.modal-content {
    border: white;
}

table {
    width: 100%;
}

.saveButton {
    background-color: #0a407a;
    color: #ffffff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: auto;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    align-self: center;
}
</style>
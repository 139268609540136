<template>
  <div v-if="showProjectsModal" class="modal-overlay" @close="closeProjectsModal">
    <div class="projects-modal" @click.stop ref="myModal">
      <div class="modal-content">
        <table>
          <tr>
            <h3>{{ selectedProductName }}</h3>
          </tr>
          <div class="flex-container">
            <div class="projects-list">
              <tr>
                <h2 style="font-weight: bold;">Current Projects</h2>
              </tr>
              <tr>
                <table class="projects">
                  <thead>
                    <tr>
                      <th class="project-name-header">Project Name</th>
                      <th class="start-date-header">Start Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="project in filteredProjects" :key="project.project_id">
                      <td class="project-name">{{ project.name }}</td>
                      <td class="start-date">{{ project.start_date }}</td>
                    </tr>
                  </tbody>
                </table>
              </tr>
            </div>
            <div class="add-projects">
              <tr>
                <h2 style="font-weight: bold;">Add new Project</h2>
              </tr>
              <tr>
                <div class="form-field">
                  <input type="text" id="ProjectName" v-model="newProject.ProjectName" placeholder="Class Name" />
                </div>
              </tr>
              <tr>
                <div class="form-field">
                  <input type="date" id="StartDate" v-model="newProject.StartDate" placeholder="Start Date" />
                </div>
              </tr>
              <tr>
                <div class="form-field">
                  <input type="date" id="EndDate" v-model="newProject.EndDate" placeholder="End Date" />
                </div>
              </tr>
              <tr>
                <div class="form-field">
                  <select class="form-control rounded" id="user_id" name="user_id" v-model="newProject.user_id">
                    <option value="" disabled selected>Select an Instructor</option>
                    <option v-for="instructor in instructors" :key="instructor.user_id" :value="instructor.user_id">
                      {{ instructor.first_name + " " + instructor.last_name }} -- ID: {{ instructor.user_id }}
                    </option>
                  </select>
                </div>
              </tr>
              <tr>
                <button class="modal-submit" @click="submitClass">Save</button>
              </tr>
            </div>
          </div>
        </table>
      </div>
    </div>
  </div>
</template> 
  
<script>
import axios from "axios";
export default {
  props: {
    showProjectsModal: Boolean,
    selectedProductName: String,
    selectedProductId: Number,
  },
  data() {
    return {
      projects: [],
      filteredProjects: [],
      instructors: [],
      newProject: {
        user_id: '',
        ProjectName: '',
        start_date: '',
        end_date: '',
      },
    };
  },
  watch: {
    showProjectsModal: function (newVal) {
      if (newVal === true) {
        this.fetchProjects(this.selectedProductId);
      }
    }
  },
  methods: {
    handleEscapeKey(event) {
      if (event.key === 'Escape') {
        this.$emit('close');
      }
    },
    handleClickOutside(event) {
      if (this.$refs.myModal && !this.$refs.myModal.contains(event.target)) {
        this.$emit('close');
      }
    },
    handleShowAlert(event) {
      alert(event.detail);
    },
    async fetchProjects() {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axios.get('/api/projects', { headers });
        this.projects = response.data;
        this.filteredProjects = this.projects.filter(p => p.prod_id === this.selectedProductId);
        console.log("Filtered projects:", this.filteredProjects);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    },
    async submitClass() {
      try {
        // Get the authentication token from localStorage
        const token = localStorage.getItem('token');
        // Create the headers with the Authorization token
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        // Prepare the data for the POST request
        const data = {
          name: this.newProject.ProjectName,  // Class name
          prod_id: this.selectedProductId,  // product ID
          instructor_id: this.newProject.user_id,
          start_date: this.newProject.StartDate,
          end_date: this.newProject.EndDate,
        };
        if (!this.newProject.ProjectName) {
          window.dispatchEvent(new CustomEvent('show-alert', { detail: "Project Name is required." }));
          return;
        }
        if (!this.newProject.StartDate) {
          window.dispatchEvent(new CustomEvent('show-alert', { detail: "Start Date is required." }));
          return;
        }
        if (!this.newProject.user_id) {
          window.dispatchEvent(new CustomEvent('show-alert', { detail: "Instructor is required." }));
          return;
        }

        // Make the POST request to the API with authentication headers and data
        const response = await axios.post('/api/projects', data, { headers });

        // Check the response status code (you can customize this)
        if (response.status === 201) {
          window.dispatchEvent(new CustomEvent('show-alert', { detail: "Project added successfully" }));
        } else {
          console.error('Class assignment failed:', response.data);
          window.dispatchEvent(new CustomEvent('show-alert', { detail: "Class assignment failed" }));
        }
      } catch (error) {
        console.error('Error assigning class:', error);
        window.dispatchEvent(new CustomEvent('show-alert', { detail: "Error adding project" }));
      }
    },
    async fetchInstructors() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.display('unauthorized');
          return;
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get('/api/users', { headers });

        if (response.status === 200) {
          // Filter the response data to include only users with the role 'instructor'
          this.instructors = response.data.filter(user => user.role === 'instructor');
          console.log(this.instructors);
        }
      } catch (error) {
        console.error('Error retrieving instructors:', error);
        // Handle error
      }
    },
  },
  async mounted() {
    await this.fetchProjects();
    await this.fetchInstructors();
    document.addEventListener('click', this.handleClickOutside);
    document.addEventListener('keydown', this.handleEscapeKey);
    window.addEventListener('show-alert', this.handleShowAlert);
  },
  beforeUnmount() {
    // Don't forget to remove the event listener when the component is destroyed to avoid memory leaks
    document.removeEventListener('click', this.handleClickOutside);
    document.removeEventListener('keydown', this.handleEscapeKey);
    window.removeEventListener('show-alert', this.handleShowAlert);
  },
}
</script>
  
<style>
.flex-container {
  display: flex;
  justify-content: space-between;
  /* This will ensure there's some space between the two sections */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(97, 94, 94, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.projects-modal {
  background-color: #fff;
  width: 70%;
  max-height: 90vh;
  max-width: 90%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  display: block;
  z-index: 1001;
}

.modal-content {
  display: flex;
  justify-content: space-between;
}

.modal-content h2 {
  font-size: 20px;
  text-align: left;
}

.projects-list {
  flex: 1;
  /* This allows both divs to take equal width */
  padding: 20px;
  position: flex;
  width: 50%;
  text-align: left;
}


.divider {
  width: 2px;
  /* Width of the gray line */
  background-color: gray;
  /* Color of the divider */
  height: 100%;
  /* Takes full height of the parent div */
}

.projects,
.start-date-header,
.start-date {
  width: 20px;
  /* Adjust this value as needed */
  white-space: nowrap;
  text-overflow: ellipsis;
  /* Use ellipsis for any overflow content */
  border-collapse: collapse;
}

.projects th {
  border-bottom: 2px solid black;
  /* Add border to the bottom of the table headers */
  padding: 8px;
  /* Optional: Add some padding for visual comfort */
}

.projects td {
  border-bottom: 1px solid #e0e0e0;
  /* Add border to the bottom of the table cells */
  padding: 8px;
  /* Optional: Add some padding for visual comfort */
}

.start-date-header,
.project-name-header {
  background-color: rgb(225, 233, 225);
  border-collapse: separate;
}

.modal-submit {
  background-color: #02092b;
  color: #ffffff;
  border: none;
  width: 70px;
  height: 40px;
  padding: 10px;
  margin-right: 40px;
  margin-left: 15%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.add-projects {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 0px;
  padding-top: 20px;
  width: 40%;

}

.form-field {
  width: 60%;
  margin-bottom: 10px;
}

.form-field input[type="text"],
.form-field input[type="date"],
.form-field select {
  width: 100%;  /* Takes the full width of its container */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-control.rounded {
  height: auto; /* Ensure dropdown height is similar to input boxes */
}

</style>
  
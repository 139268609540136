<template>
  <div class="examinable-modal-overlay" @click="close">
    <div class="examinable-modal" @click.stop>
      <div>
        <h2 style="text-align: center;">Examinable Tasks</h2>
      </div>
      <div class="exam-pagination-button-container">
        <div class="exam-pagination-container">
          <Pagination :total-pages="totalPages" :per-page="perPage" :current-page="currentPage"
            @page-change="onPageChange" />
        </div>
        <div class="exam-pagination-info">
          Showing {{ startIndex + 1 }}-{{ endIndex }} of {{ sampleData.length }} Tasks
        </div>
        <button @click="resetSelection" class="reset-button">Reset Selection</button>
      </div>
      <table style="width:100%">
        <thead>
          <tr>
            <th class="scope">Task No</th>
            <th class="mo">Ratings</th>
            <th class="exam">Module</th>
            <th class="ref">Chapt no</th>
            <th class="cat">Location</th>
            <th class="est">Area</th>
            <th class="chapt">AMTOSS</th>
            <th class="techlog">Activity</th>
            <th class="techlog">Category</th>
            <th class="techlog">level</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in paginatedTasks" :key="row.sno">
            <td style="width:1%;">
              <input type="checkbox" :value="row" :checked="isSelected(row)" @change="toggleTaskSelection(row)" />
              {{ (currentPage - 1) * perPage + index + 1 }}
            </td>
            <td>{{ row.scope }}</td>
            <td>{{ row.mo }}</td>
            <td>{{ row.description }}</td>
            <td>{{ row.reference }}</td>
            <td>{{ row.category }}</td>
            <td>{{ row.estTime }}</td>
            <td>{{ row.chapterNo }}</td>
            <td class="technical-logbook-entry" style="font-size: 12px;">
              Technical logbook entry <br>
              <a :href="`/assessment_evaluation.html`" target="_blank">
                assess_eval {{ (currentPage - 1) * perPage + index + 1 }}
              </a>
              |
              <a :href="`/technical_logbook_entry.html`" target="_blank">
                tech_lb_entry {{ (currentPage - 1) * perPage + index + 1 }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <fieldset class="selected-tasks-display">
        <legend>Selected Tasks</legend>
        <div v-if="selectedTasksList.length === 0" class="placeholder-message">
          Selected tasks will be displayed here
        </div>
        <table v-else style="width:100%">
          <tbody>
            <tr v-for="task in selectedTasksList" :key="task.sno">
              <td class="sno">{{ task.task_no }}</td>
              <td class="scope">{{ task.scope }}</td>
              <td class="mo">{{ task.mo }}</td>
              <td class="exam">{{ task.description }}</td>
              <td class="ref">{{ task.reference }}</td>
              <td class="cat">{{ task.category }}</td>
              <td class="est">{{ task.estTime }}</td>
              <td class="chapt">{{ task.chapterNo }}</td>
              <td class="techlog">{{ task.logbookEntry }}</td>
            </tr>
          </tbody>
        </table>
      </fieldset>
      <div>
        <button class="add-examinable-tasks" :disabled="selectedTasksList.length === 0" @click="addExaminableTasks">
          Add Examinable Tasks
        </button>
      </div>
    </div>
  </div>
</template>

  
<script>
import Pagination from '@/components/Examiner/TablePagination.vue';
import axios from 'axios';
export default {
  data() {
    return {
      currentPage: 1,
      perPage: 5,
      sampleData: [],
      selectedTasks: [],
    };
  },
  components: {
    Pagination,
  },
  props: {
    showExaminableTasksModal: Boolean,
    nextTaskNumber: Number,
  },
  computed: {
    paginatedTasks() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.sampleData.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.sampleData.length / this.perPage);
    },
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      const end = this.startIndex + this.perPage;
      return end < this.sampleData.length ? end : this.sampleData.length;
    },
    selectedTasksList() {
      return Object.values(this.selectedTasks).filter(Boolean);
    },
  },
  methods: {

    onPageChange(newPage) {
      this.currentPage = newPage;
    },
    toggleTaskSelection(task) {
      const index = this.selectedTasks.findIndex(t => t.sno === task.sno);
      if (index > -1) {
        // Task is currently selected, remove it
        this.selectedTasks.splice(index, 1);
      } else {
        // Task is not selected, add it
        this.selectedTasks.push(task);
      }
    },
    resetSelection() {
      // Reset the selected tasks
      this.selectedTasks = [];
      // Optional: Reset all checkboxes (if they are not automatically reactive)
      this.$nextTick(() => {
        const checkboxes = this.$el.querySelectorAll('.examinable-modal input[type="checkbox"]');
        checkboxes.forEach(checkbox => {
          checkbox.checked = false;
        });
      });
    },
    getReportUrl(type, index) {
      return `/${type}_report_${this.getReportNumber(index)}.html`;
    },
    getReportNumber(index) {
      return (this.currentPage - 1) * this.perPage + index + 1;
    },
    isSelected(task) {
      return this.selectedTasks.includes(task);
    },
    close() {
      this.$emit('close');
    },
    addExaminableTasks() {
      alert('Examinable tasks successfully added to the Tasks list');
    }
  },

  mounted() {
    const token = localStorage.getItem('examiner-token');
    const headers = { Authorization: `Bearer ${token}` };

    let result = axios.get('/api/tasks', { headers });
    this.tasks = result.data;
  }
};
</script>
  
<style>
.examinable-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(97, 94, 94, 0.5);
  z-index: 1000;
  JUSTIFY-CONTENT: CENTER;
}

.examinable-modal {
  max-width: 1200px;
  /* Maximum modal width */
  width: 100%;
  /* Allows modal width to scale on smaller screens */
  margin: auto;
  /* Centers the modal in the overlay container */
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1002;
}

.examinable-modal table {
  width: 100%;
  border-collapse: collapse;
  /* Ensures borders are nice and tight */
}

.examinable-modal th,
.examinable-modal td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}

/* Style for the table header */
.examinable-modal th {
  background-color: #f2f2f2;
  /* Light grey background for the header */
}

/* Style for alternate table rows */
.examinable-modal tr:nth-child(even) {
  background-color: #f9f9f9;
  /* Light grey background for even rows */
}

/* Style for non-alternate table rows, if needed */
.examinable-modal tr:nth-child(odd) {
  background-color: #ffffff;
  /* White background for odd rows */
}


.exam-pagination-button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-align: center;
}

.exam-pagination-info {
  margin-left: 230px;
}

.reset-button {
  margin-left: 350px;
  border-radius: 5px;
  width: 150px;
  background-color: #a52c0d;
  color: white;
  margin-right: 20px;
  padding: 5px;
}

.selected-tasks-display {
  border-top: 1px solid #ccc;
  /* Adjust color as needed */
  padding: 10px;
  margin-top: 20px;
  width: 100%;
}

.selected-tasks-display legend {
  padding: 0 5px;
  /* Padding inside the legend */
  width: auto;
  /* Allows the legend to size to its contents */
  margin-bottom: 10px;
  /* Space below the legend */
  text-align: left;
}

.selected-tasks-display table {
  width: 100%;
  /* Table occupies full width of the fieldset */
  border-collapse: collapse;
  /* Ensures that table borders are collapsed into a single border */
}

.selected-tasks-display th,
.selected-tasks-display td {
  border: 1px solid #ddd;
  /* Light grey border for each cell */
  padding: 8px;
  /* Padding within each cell */
  text-align: left;
  /* Aligns text to the left of the cell */
}

.selected-tasks-display th {
  background-color: #f2f2f2;
  /* Light grey background for headers, if you add them later */
}

/* If you want zebra striping for the rows */
.selected-tasks-display tr:nth-child(even) {
  background-color: #f9f9f9;
  /* Light grey background for even rows */
}

.selected-tasks-display tr:nth-child(odd) {
  background-color: #ffffff;
  /* White background for odd rows */
}

.placeholder-message {
  color: grey;
  text-align: center;
  font-style: italic;
}

.add-examinable-tasks {
  margin-top: 20px;
  border-radius: 5px;
  width: 200px;
  background-color: #02092b;
  color: white;
  margin-left: 950px;
  padding: 5px;
}

.sno,
.scope,
.mo,
.est,
.chapt,
.cat {
  width: 0.2%;
}

.exam {
  width: 10%
}

.ref {
  width: 1%
}

.techlog {
  width: 2%
}
</style>
  
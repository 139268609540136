<template>
    <div class="modal-overlay" @click="closeModal">
        <div class="products-modal" @click.stop>
            <div class="modal-content" @click.stop> <!--stop the click event from propagating to the overlay -->
                <h2>Add a New Aircraft</h2>
                <table>
                    <tr>
                        <input class="productsearch" v-model="aircraftName" type="text" style="width: 100%"
                            placeholder="Aircraft Name" />
                    </tr>
                    <tr>
                        <button style="width: 40%;" class="saveButton" @click="saveButton">Save</button>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            aircrafts: [],
        };
    },

    mounted() {
        this.fetchData();
        window.addEventListener('show-alert', this.handleShowAlert);
    },
    beforeUnmount() {
        window.removeEventListener('show-alert', this.handleShowAlert);
    },


    methods: {
        async fetchData() {
            try {
                const token = localStorage.getItem('token');
                const headers = { Authorization: `Bearer ${token}` };
                let response = await axios.get('/api/aircraft', { headers });
                this.aircrafts = response.data;
                console.log(response);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },

        closeModal() {
            this.$emit('close');
        },
        async saveButton() {
            // Check if values are set before making the API call
            if (this.aircraftName) {
                //Check for duplicate aircraft name
                const isDuplicate = this.aircrafts.some(aircraft => aircraft.name === this.aircraftName);

                if (isDuplicate) {
                    console.error("Aircraft name already exists. Please choose a different name.");
                    window.alert('Aircraft name already exists');
                } else {
                    try {
                        const token = localStorage.getItem('token');
                        const headers = { Authorization: `Bearer ${token}` };
                        const data = {
                            name: this.aircraftName
                        };
                        console.log("data", data);
                        const response = await axios.post('/api/aircraft', data, { headers });

                        if (response.status === 201) {
                            window.alert('Aircraft added Successfully');
                            this.closeModal();
                        } else {
                            window.dispatchEvent(new CustomEvent('show-alert', { detail: "Aircraft addition failed" }));
                        }
                    } catch (error) {
                        console.error("There was an error with the fetch operation:", error);
                    }
                }
            } else {
                console.error("Please enter an aircraft name.");
            }
        }

    }
};

</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.products-modal {
    background-color: #fff;
    max-width: 90%;
    min-height: 20%;
    min-width: 30%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    position: relative;
    display: block;
    z-index: 1001;
}

.aircraftdropdown,
.productsearch,
.specificdropdown {
    height: 40px;
    /* adjust this value as needed */
    margin-bottom: 10px;
    width: 80%;
}


.modal-content {
    border: white;
}

.saveButton {
    background-color: #0a407a;
    color: #ffffff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: auto;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    align-self: center;
}
</style>